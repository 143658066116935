<template>
  <div v-if="$store.state.common.sRegiStatus == 0">
    <h4 style="color:#696969;">現在は仮登録状態です。企業情報画面で必要項目を登録してください。</h4>
    <div>
      <div>
          <router-link to="/customer-company">企業情報</router-link>
      </div>
    </div>
  </div>
  <div v-else-if="$store.state.common.sRegiStatus == 1">
    <div>
      <div>
          <router-link to="/customer-company">企業情報</router-link>
      </div>
      <div>
          <router-link to="/customer-workplace">職場情報</router-link>
      </div>
      <div>
          <router-link to="/customer-employee">社員情報</router-link>
      </div>
      <div>
          <router-link to="/customer-sc-config">ストレスチェック設定</router-link>
      </div>
    </div>
  </div>

    <!-- <p><b>{{ vMessage }}</b></p> -->
</template>

<script>
  import constant from '@/constant.js'

  export default {
    created() {
      // 画面読み込み中・・・
      this.$store.state.common.sIsLoading = true;
      // ログイン状態判定
      this.doAuthLoginInfo()
      if (!this.$store.state.common.sIsLogined) {
        this.$router.push('/login')
      }
      // 画面タイトル
      this.$store.commit('common/doSetScreenTitle', {
        vScreenTitle: 'クライアント/ホーム'
      });
    },
    methods: {
      // ID,パスワード照合
      doAuthLoginInfo() {
        if (sessionStorage.getItem('storageId')) {
          let createHash = require("sha256-uint8array").createHash;
          let tmp = createHash().update(sessionStorage.getItem('storagePw')).digest("hex");
          this.$axios.post(constant.cServerRoute.common.login, {
            pLoginId: sessionStorage.getItem('storageId'),
            pLoginPw: tmp
          })
          .then(function(response){
            console.log(response);
            if (response.data.return_result != constant.cServerReturnResult.success) {
              this.$store.state.common.sIsLogined = false;
            }
          }.bind(this))
          .catch(function(error){
            console.log(error);
            this.$store.state.common.sIsLogined = false;
          })
        }
      }
    },
  }
</script>
